.containerImgModalTitle {
  margin-top: 2.5rem;
}

.containerTextModalTitle {
  margin-top: 5rem;
}

.imgInfoBgRequestAccessModal {
  position: absolute;
}

.imgInfoRequestAccessModal {
  position: absolute;
  margin-top: 10px;
}

.treeIcon {
  position: relative;
  top: 4px;
  margin-right: 3px;
}

.searchButton {
  position: absolute;
  top: 2.2rem;
  right: 1rem;
  background: 0;
  border: 0;
}

.searchInput {
  padding-right: 2rem;
}

.currentCapability,
.parentCapability {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: #26890d;
  color: #eef5e6;
  margin: 4px 2px;
  padding: 4px 8px;
  height: 28px;
  border-radius: 16px;
  list-style: none;
}

.parentCapability {
  background: #68a927;
}
