/* .container-img-modal-title {
  margin-top: 2.5rem;
}

.container-text-modal-title {
  margin-top: 5rem;
}
.request-access-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 5rem;
  color: #000000;
}
.modal-desc {
  margin-top: 5rem;
}
.img-info-bg-request-access-modal {
  position: absolute;
}

.img-info-request-access-modal {
  position: absolute;
  margin-top: 10px;
}

.img-request-access {
  width: 90px;
  height: 90px;
}

.img-role-info {
  position: absolute;
  width: 355.45px;
  height: 320px;
  left: 30px;
} */

.containerImgModalTitle {
  margin-top: 2.5rem;
}

.containerTextModalTitle {
  margin-top: 5rem;
}

.emailAddressTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #53565a;
}

.emailContainer {
  height: 80px;
  background: #ffffff;
  border: 1px solid #cccccc;
}

.imgInfoBgRequestAccessModal {
  position: absolute;
}

.imgInfoRequestAccessModal {
  position: absolute;
  margin-top: 10px;
}

.modalRequestAccess {
}
