.containerImgModalTitle {
  margin-top: 2.5rem;
}

.imgInfoBgRequestAccessModal {
  position: absolute;
}

.imgInfoRequestAccessModal {
  position: absolute;
  margin-top: 15px;
}

.containerTextModalTitle {
  margin-top: 5rem;
}

.containerTextModalSubtitle h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #777777;
}

.capabilityTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #26890d;
}

.capabilityPill {
  padding: 2px 8px;
  background-color: #26890d;
  border-radius: 16px;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.capabilityDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.capabilityDocumentsList {
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.capabilityDocumentsListItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #26890d;
  border: 1px solid #26890d;
  border-radius: 16px;
  margin: 4px 2px;
  padding: 4px 8px;
}

.modalFooter {
    justify-content: flex-start;
    background-color: #f9f9f9;
}
