.content-body-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #f0f0f0;
  color: #777777;
  /* display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; */
}

footer {
  position: fixed;
  width: 100%;

  background-color: #f0f0f0; /* Light grey background */

  bottom: 0;
  left: 0;
}
.footer-description {
  margin: 0;
  margin-left: 1vw;
  flex: 1;
}
.footer-cookie-link {
  margin: 0;
  margin-right: 1vw;
}
.footer-cookie-setting-link {
  margin: 0;
  margin-right: 1vw;
  padding: 0.35vw;
  border: 0px;
  background-color: #26890d;
  color: #f0f0f0;
}
