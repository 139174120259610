.node {
  position: relative;
  padding: 4px 8px;
  display: inline-grid;

  text-decoration-line: none;
  transition: 0.5s;

  background: #ffedbd;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;

  /* Typography/Primary */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  text-align: center;
  /* background-color: #26890D; */
}

.clicked {
  background-color: green;
  color: #ffffff;
}
