.appContainer {
  display: flex;
  height: auto;
  margin-bottom: 150px;
  border-radius: 16px;
}
.containerWrapper {
  /* position: absolute; */
  left: 1px;
  width: 100%;
  height: auto;
  transition: width 0.5s ease-in-out;
}

.container1 {
  position: relative;
  width: auto;
  height: auto;
  left: 0%;
  /* margin-top: -30px; */
  /* min-height: 1045px; */
  height: 610px;
  overflow-y: hidden;
  overflow-x: hidden;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  flex-grow: 1;
  transition: width 0.3s ease-in-out;
}

.sidebarOpen .containerWrapper {
  position: absolute;
  left: 1%;
  width: calc(100% - 35.7%);
  transition: width 0.3s ease-in-out;
}
.selectDomain {
  position: absolute;
  top: 100%;
}
.drop-down {
  position: relative;
}

/* This will not apply here */
.userSelect {
  position: relative;
  top: -140px;
  left: 1000px;
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 16px;
  gap: 12px;

  width: 127px;
  height: 36px;
  border: 1px solid #aaaaaa;
  border-radius: 18px;
}

.drawer {
  position: absolute;
  right: 0;
  width: 405px;
  height: 610px;
  margin-right: 2%;
  top: 150px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px 16px 16px 8px;
  transition: right 0.5s ease-in-out;
  z-index: 1;
  overflow: auto;
  overflow-x: hidden;
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.04);
}

.capDesc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 1px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 15px 25px;
  min-height: 200px;
}
.capabilityName {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  word-wrap: break-word;
}
.capabilityDescription {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #777777;
}

.options {
  padding: 15px 25px;
}

.edit {
  position: absolute;
  margin-top: -40px;
  right: 70px;
  border: #ffffff;
  background-color: #ffffff;
}
.delete {
  position: absolute;
  margin-top: -40px;
  right: 40px;
  border: #ffffff;
  background-color: #ffffff;
}
.delete:disabled,
.delete[disabled] {
  color: #cccccc;
}
.close {
  position: absolute;
  margin-top: -75px;
  height: 100px;
  right: 10px;
  font-size: 22px;
  border: #ffffff;
  background-color: #ffffff;
}
.readMore {
  color: #26890d;
  text-decoration: none;
  border: #ffffff;
  background-color: #ffffff;
}
.readMore:hover {
  color: #26890d;
  text-decoration: none;
}
.readMoreChildCap {
  position: relative;
  margin-top: auto;
  color: #26890d;
  text-decoration: none;
  border: #ffffff;
  background-color: #ffffff;
  font-size: 14px;
}
.readMoreChildCap:hover {
  color: #26890d;
  text-decoration: none;
}
.readMoreChildCapability {
  color: #777777;
  text-decoration: none;
  border: #ffffff;

  background-color: #ffffff;
}
.readMoreChildCapability:hover {
  color: #777777;
}
.childDesc {
  color: #777777;
  text-decoration: none;
  font-size: 14px;
}
.childDesc:hover {
  color: #777777;
}
.businessModels {
  color: #26890d;
  text-decoration: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  position: absolute;
  left: 15%;
  line-height: 20px;
}
.businessModelsPublicUser {
  color: #26890d;
  text-decoration: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  left: 15%;
}
.businessModels:hover {
  color: #26890d;
}
.businessModelsPublicUser:hover {
  color: #26890d;
}
.Architecture {
  color: #26890d;
  text-decoration: none;
}
.Journeys {
  color: #26890d;
  text-decoration: none;
}
.secondRow {
  position: relative;
  top: 15px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #777777;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.thirdrow {
  margin-top: 10px;
}
.fourthRow {
  position: relative;
  top: -10px;
}
.questionMark {
  position: relative;
  top: 50px;
  text-align: center;
}
.whyImage {
  position: relative;
  left: 100%;
}
.Documents {
  margin-top: 30px;
}

.tooltipEditCap {
  cursor: pointer;
}

.tooltiptextEditCap {
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: #26890d;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.04);
  /* text-align: center; */
  text-align: center;
  border-radius: 6px;

  /* padding-left: 20px; */
  position: absolute;
  z-index: 2;
  top: 8%;
  right: 0;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  height: auto;
  min-height: 10px;
  white-space: pre-wrap;
}

.tooltipEditCap:hover .tooltiptextEditCap {
  visibility: visible;
  opacity: 1;
}
.hoverText {
  text-align: center;
}

.container1::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.container1::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.container1::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.drawer::-webkit-scrollbar {
  width: 5px;
  height: 1px;
  border-radius: 16px;
}

.drawer::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 16px;
}

.drawer::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 16px;
}

/* Media Query for screens with a maximum width of 768px */
@media (max-width: 768px) {
  .drawer {
    width: 100%;
    height: auto;
    margin-right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }
}

/*Media Query for screens with a maximum width of 768px */
@media (max-width: 768px) {
  .sidebarOpen .containerWrapper {
    width: calc(100% - 0%);
  }
}

/* Media Query for screens with a maximum width of 480px */
@media (max-width: 480px) {
  .sidebarOpen .containerWrapper {
    width: calc(100% - 0%);
  }
}

/* Media Query for screens with a minimum width of 1440px */
@media (min-width: 1440px) {
  .sidebarOpen .containerWrapper {
    width: calc(100% - 300px);
  }
  .container1 {
    width: 10000px;
  }
}
