.topContent {
  position: relative;
  margin-left: 2%;
  margin-bottom: 0.5rem;
}
.content {
  position: relative;
  align-items: center;
  padding: 0px 0px 2px;
  gap: 60%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}
.contentTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  display: inline-block;
}
.description {
  position: relative;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Secondary/777777 */

  color: #777777;
  /* top:-30px; */
}
.showCapability {
  position: relative;
  /* margin-right: 2%;
    float: right; */
  color: #26890d;
  box-sizing: border-box;
  /* Typography/Primary */
  text-decoration: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  align-items: center;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 4px;

  /* Primary/FFFFFF */

  background: #ffffff;
  /* Primary/26890D */

  border: 1px solid #26890d;
}
