* {
  box-sizing: border-box;
  font-family: "Open Sans";
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
}

.navbar {
  width: 100%;
  height: 60px;

  background: #000000;
}

.navbar-container-user-profile {
  border: 1px solid grey;
  border-radius: 5rem;
  /* width: 6rem; */
}

.navbar-divider {
  width: 1px;
  color: #aaaaaa;
}

/* .nav-link-active{
  background: #26890D;
} */

.active {
  background: #26890d;
}

.nav-link-highlight:active {
  background: #26890d;
}
.clicked {
  background-color: #26890d;
}

.navbar-project-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  color: #ffffff;
}

.navbar-icon-logo {
  width: 18.83px;
  height: 16px;
}

.icon-user-profile-pfp {
  margin-left: 1rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.user-type-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.user-profile-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #26890d;
}

.user-profile-designation {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #777777;
}
