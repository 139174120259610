.main {
  margin-top: 5%;
}
.selectIndustry {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  padding: 1px 2px;
  width: 95%;
  height: 43px;
  box-sizing: border-box;
}
