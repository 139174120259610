.accordionTitle{
    position: relative;
    left:35px;

        /* Typography/Secondary heading */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */


    /* Black #000000 */

    color: #000000;
}
.angleDownIcon{
    position: relative;
    left:5px;
    top:25px;
}