html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1;
}