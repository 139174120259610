* {
  box-sizing: border-box;
  font-family: "Open Sans";
  font-style: normal;
}
.addUserModalTitle,
.editUserModalTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.addUserModalSubTitle,
.editUserModalSubTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #777777;
}

.btnAddUser {
  width: 92px;

  background: #26890d;
}

.btnCustomSuccess {
  padding: 8px 16px;
  height: 40px;
  background: #26890d;
  border-radius: 0%;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.btnEditUser {
  border: none;
  background: none;
}

.btnRemoveUser {
  border: none;
  background: none;
}

body {
  margin: 0;
  padding: 0;
}

.cardTitleAllUsers,
.cardBodyElevationRequests {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.containerTextModalTitle {
  margin-top: 5rem;
}

.containerUserManagementPage {
  margin: 2rem;
  padding: 0;
}

.deloitteButton {
  padding: 4px 16px;
  height: 32px;
  border-radius: 0;
}

.deloitteButtonTextSuccess {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.deloitteButtonTextDanger {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #26890d;
}

.iconArrowDown {
  color: #26890d;
}

.iconArrowDown:active {
  transform: rotate(180deg);
}

.iconEditUser {
  color: #26890d;
}

.iconRemoveUser {
  color: #26890d;
}

.iconSearch {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #aaaaaa;
}

.imgCloud {
  border: 1px solid #e0e0e0;
  width: 60px;
  height: 60px;
  border-radius: 5rem;
  padding: 9px;
}

.imgInfoBgAddUserModal,
.imgInfoBgEditUserModal {
  position: absolute;
}

.imgInfoAddUserModal,
.imgInfoEditUserModal {
  position: absolute;
  margin-top: 10px;
}

.noElevationRequestsImg {
  margin-top: 10rem;
  margin-bottom: 1rem;
}

.noElevationRequestsTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.noElevationRequestsText {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #777777;
  margin-top: -5px;
}

.removeUserModalSubTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #777777;
}

.searchBar {
  border: none;
}

.searchBarOutline {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 24px;
  padding: 4.5px;
}

.reasonTextData {
  word-break: break-all;
}

.tableElevationRequestsHead {
  background: #e5e5e5;
}

.tableElevationRequestsDataText {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #26890d;
}

.tableElevationRequestsDataTextAccessType,
.tableElevationRequestsDataTextRequestDate {
  color: #000000;
}

.tableElevationRequestsHeaderTitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #777777;
}

.tableHeaderReason {
  width: 18rem;
}

.userManagementPageCard {
  width: 106%;
  height: 487px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
}

.userManagementPageMainTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.userManagementPageUpdateInfoText {
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #aaaaaa;

  margin-left: 1rem;
  margin-top: 0.6rem;
}

.userManagementPageDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #777777;

  margin-top: -0.25rem;
}

.userManagementPageElevationRequestsTableTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #26890d;
  border: none;
  background: none;
}

.userManagementPageElevationRequestsTableTitleActive {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #26890d;
  border: none;
  background: none;
}

.userManagementPageAllUsersTableTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #26890d;
  border: none;
  background: none;
}

.userManagementPageAllUsersTableTitleActive {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #26890d;
  border: none;
  background: none;
}
