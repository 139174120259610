* {
  box-sizing: border-box;
  font-family: "Open Sans";
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
}

.btnAccessCapabilities {
}

.btnCustomSuccess {
  padding: 8px 16px;
  height: 40px;
  background: #26890d;
  border-radius: 0%;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.btnContact {
}

.btnLink {
  text-decoration: none;
  color: #ffffff;
}

.btnLink:hover {
  color: #ffffff;
}

.btnRequestAccess {
}

.contactDescription {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;

  color: #000000;
}

.containerBox {
  width: 980px;
  height: 176px;
  margin-top: 69px;

  background: #ffffff;
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.containerCircle {
  position: absolute;
  width: 138px;
  height: 138px;

  background: #ffffff;
  border-radius: 200px;
}

.containerImgModalTitle {
  margin-top: 2.5rem;
}

.containerTextModalTitle {
  margin-top: 5rem;
}

.contentBodyContact {
  /* position: absolute; */
  width: 100%;
  height: 135px;
  /* margin-top: 73.35rem; */
}

.contentBodyRequestAccess {
  /* position: absolute; */
  width: 100%;
  height: 293px;
  padding-top: 3px;
  /* margin-top: 55rem; */
  background: #c0e8b6;
}

.contentBodyRoleInfo {
  /* position: absolute; */
  width: 100%;
  /* height: 400px; */
  /* margin-top: 29rem; */
}

.contentBodyUCMGInfo {
  /* position: absolute; */
  width: 100%;
  /* height: 246px; */
  background: #fafafa;
}

.contentBodyVision {
  /* position: absolute; */
  width: 100%;
  /* height: 246px; */
  /* margin-top: 15rem; */
}

.emailAddressTitle {
  font-weight: 400;
  font-size: 32px;
  line-height: 16px;
  color: #53565a;
}

.emailContainer {
  height: 80px;
  background: #ffffff;
  border: 1px solid #cccccc;
}

hr {
  left: 106px;
  margin-top: 2rem;
  border: 1px solid #cccccc;
}

.imgInfoBgRequestAccessModal {
  position: absolute;
}

.imgInfoRequestAccessModal {
  position: absolute;
  margin-top: 10px;
}

.imgRequestAccess {
  width: 90px;
  height: 90px;
}

.imgRoleInfo {
  position: absolute;
  width: 355.45px;
  height: 320px;
  left: 30px;
}

.imgUCMG {
  margin-left: 6rem;
}

.modalRequestAccess {
}

.requestAccessDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
}

.requestAccessTextContent {
  margin-top: 4.75rem;
}

.reasonContainer {
  background: #ffffff;
  border: 1px solid #cccccc;
}

.roleInfoColumnContent {
  margin-left: 18rem;
}

.roleInfoDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
}

.roleInfoSubTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.roleInfoTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.reasonTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #53565a;
}

.UCMGDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
}

.UCMGSubTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  color: #aaaaaa;
}

.UCMGTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;

  color: #000000;
}

.visionTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;

  color: #000000;
}

.visionDescription1 {
  font-size: 14px;
  line-height: 20px;

  color: #000000;
}

.visionDescription2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  font-family: "Open Sans";
  font-style: normal;

  text-align: center;

  color: #000000;
}
.footerPage {
  bottom: 0;
}
.form-label {
  font-size: 16px;
}
