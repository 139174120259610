:root {
  --color-primary: #26890d;
  --color-secondary: #777777;
  --color-black: #000000;
}

/* Heading styles */
.h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

/* Color styles */
.color-primary {
  color: var(--color-primary);
}

.color-black {
  color: var(--color-black);
}

/* Form styles */
.form-label {
  font-weight: 400;
  color: #121212;
  font-size: 16px;
  line-height: 16px;
  /* color: var(--color-secondary); */
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.w-100 {
  width: 100%;
}
