.capabilityAccordion {
    border-color: #e5e5e5;
}

.capabilityAccordionHeader button,
.capabilityAccordionHeader button:not(.collapsed) {
    color: #000000;
    background-color: #f9f9f9 !important;
    padding: 10px 20px;
    font-size: 14px;
}

.capabilityAccordionHeader button:not(.collapsed):after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000000%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.capabilityDocumentList {
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.capabilityDocumentListItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #26890d;
  background: #eef5e6;
  border-radius: 16px;
  margin: 4px 2px;
  padding: 4px 8px;
}

.capabilityDocumentRemoveButton {
    position: relative;
    top: 2px;
    background: 0;
    border: 0;
    color: #777777;
}
