.containerImgModalTitle {
  margin-top: 2.5rem;
}

.containerTextModalTitle {
  margin-top: 5rem;
}

.imgInfoBgRequestAccessModal {
  position: absolute;
}

.imgInfoRequestAccessModal {
  position: absolute;
  margin-top: 10px;
}

.treeIcon {
  position: relative;
  top: 4px;
  margin-right: 3px;
}

.searchButton {
  position: absolute;
  top: 2.2rem;
  right: 1rem;
  background: 0;
  border: 0
}

.searchInput {
  padding-right: 2rem;
}
