.treeul {
  padding-top: 20px;
  /* padding-left: 5px; */
  position: relative;
  top: 10%;
  transition: 0.5s;
}
.treeli {
  display: inline-table;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 10px;
  transition: 0.5s;
}
.treeli::before,
.treeli::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 51%;
  height: 10px;
}
.treeli::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}
.treeli:only-child::after,
.treeli:only-child::before {
  display: none;
}
.treeli:only-child {
  padding-top: 0;
}
.treeli:first-child::before,
.treeli:last-child::after {
  border: 0 none;
}
.treeli:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.treeli:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}
.treeul ul::before {
  content: "";
  position: absolute;
  left: calc(50% - 0px);
  top: 0;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}
.node {
  position: relative;
  padding: 4px 8px;
  display: inline-grid;
  width: 100%;
  min-width: 100px;
  text-decoration-line: none;
  transition: 0.5s;
  color: #000;
  background: #ffedbd;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.parentNode {
  min-height: 50px;
  min-width: 100px;
  background-color: #4682b4; /* Purple */
  color: #ffffff; /* White */
  font-weight: bold;
}

.label {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  min-width: 30px;
  position: absolute;
  width: auto;
  height: auto;
  left: -10px;
  top: -20px;
  font-size: 10px;
  background: #ffffff;
  border: 0.5px solid #cccccc;
  border-radius: 16px;
  z-index: 1;
}

.iconArrowDown {
  position: relative;
  top: 22px;
  right: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.iconArrowUp {
  /* top: 22px; */
  /* transform: rotate(180deg); */
  position: relative;
  top: 22px;
  right: 50%;
  z-index: 1;
  transform: translateX(-50%) rotate(180deg);
}
.clicked {
  background-color: #26890d;
  color: #ffffff;
}

.treespan {
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #666;
  padding: 8px;
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 1px;
  font-weight: 500;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #fff;
  color: #000;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.04);
  /* text-align: center; */
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  /* padding-left: 20px; */
  position: absolute;
  z-index: 2;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  height: auto;
  min-height: 10px;
  white-space: pre-wrap;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.hoverText {
  text-align: center;
}
