@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container {
  position: relative;
  left: 0;
  top: 30px;
  width: 100%;
  height: auto;
  overflow-x: hidden;
}

.row {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 330px;
}

.tree {
  position: relative;
  top: -20px;
  background-color: #fff;
  width: auto;
  min-width: 1200px;
  height: 360px;
  text-align: center;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: nowrap;
  display: inline-block;
}

.plus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 4px;
  background: #26890d;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.36); */
  border-radius: 20px;
  position: relative;
  height: 35px;
  width: 35px;
  border: none;
}

.plus:disabled,
.plus[disabled] {
  background: #cccccc;
}

.export {
  position: relative;
  top: 0%;
  /* left: 90%; */
  background-color: #ffffff;
  border: #ffffff;
  font-size: 25px;
}
.update {
  position: relative;
  top: 0%;
  /* left: 90%; */
  background-color: #ffffff;
  border: #ffffff;
  font-size: 25px;
}

.treeul {
  padding-top: 20px;
  position: relative;
  top: 10%;
  transition: 0.5s;
  padding-left: 0px !important;
}
.treeli {
  display: inline-table;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 10px;
  transition: 0.5s;
  padding-left: 0px !important;
}
.treeli::before,
.treeli::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 51%;
  height: 10px;
  padding-left: 0px !important;
}
.treeli::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
  padding-left: 0px !important;
}
.treeli:only-child::after,
.treeli:only-child::before {
  display: none;
}
.treeli:only-child {
  padding-top: 0;
}
.treeli:first-child::before,
.treeli:last-child::after {
  border: 0 none;
}
.treeli:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.treeli:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}
.treeul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}
.node {
  position: relative;
  left: 5px;
  padding: 4px 8px;
  display: inline-grid;
  text-decoration-line: none;
  transition: 0.5s;
  background: #ffedbd;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000;
}

.treespan {
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #666;
  padding: 8px;
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 1px;
  font-weight: 500;
}

.tooltipAddCap {
  position: relative;

  cursor: pointer;
}

.tooltiptextAddCap {
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: #26890d;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.04);
  /* text-align: center; */
  text-align: center;
  border-radius: 6px;

  /* padding-left: 20px; */
  position: absolute;
  z-index: 2;
  top: 90%;
  left: 6%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  height: auto;
  min-height: 10px;
  white-space: pre-wrap;
}

.tooltipAddCap:hover .tooltiptextAddCap {
  visibility: visible;
  opacity: 1;
}
.hoverText {
  text-align: center;
}

.tooltip {
  position: relative;
  cursor: pointer;
}

.tooltipContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-right: 2%;
  height: 10%;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: #26890d;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.04);
  /* text-align: center; */
  text-align: center;
  border-radius: 6px;

  /* padding-left: 20px; */
  position: absolute;
  z-index: 2;
  top: 90%;
  /* left: 92%; */
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  height: auto;
  min-height: 10px;
  white-space: pre-wrap;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.hoverText {
  text-align: center;
}

.row::-webkit-scrollbar {
  position: absolute;
  width: 6px;
  height: 8px;
}

.row::-webkit-scrollbar-thumb {
  background-color: #ccc;
  height: 8px;
}

.row::-webkit-scrollbar-track {
  background-color: transparent;
  height: 8px;
  width: 100px;
}
.row::-webkit-scrollbar-thumb:hover {
  background-color: grey;
}

.tree::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

.tree::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.tree::-webkit-scrollbar-track {
  background-color: transparent;
}
.tree::-webkit-scrollbar-track :hover {
  background-color: grey;
}

/* Set the width and color of the scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
